<template>
  <div id="deviceMaintenance">
    <el-dialog
      :title="deviceMaintenanceFormTitle"
      width="1200px"
      :visible.sync="deviceMaintenanceDialogVisible"
      :close-on-click-modal="false"
      @close="deviceMaintenanceDialogClose"
    >
      <el-form
        ref="deviceMaintenanceFormRef"
        :model="deviceMaintenanceForm"
        :rules="deviceMaintenanceFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="维修保养日期" prop="maintenanceDate">
              <el-date-picker
                v-model="deviceMaintenanceForm.maintenanceDate"
                placeholder="请选择维修保养日期"
                value-format="yyyy-MM-dd"
                :disabled="deviceMaintenanceFormTitle !== '新增设施设备维修保养记录'
                  && deviceMaintenanceFormTitle !== '修改设施设备维修保养记录'
                  && deviceMaintenanceFormTitle !== '设施设备维修保养记录详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-toolbar
            v-if="deviceMaintenanceFormTitle === '新增设施设备维修保养记录'
              || deviceMaintenanceFormTitle === '修改设施设备维修保养记录'"
          >
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="detailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <!-- <vxe-table-column
              field="deviceNo"
              title="编号"
              :edit-render="{name: '$input', props: {clearable: true}}"
            /> -->
            <vxe-table-column field="deviceNo" title="编号">
              <template v-slot="{row, column}">
                <el-select
                  v-model="row[column.property]"
                  placeholder="请选择设备"
                  clearable
                  @change="devChange(row)"
                >
                  <el-option
                    v-for="item in devList"
                    :key="item.id"
                    :label="item.deviceNo"
                    :value="item.deviceNo"
                  >
                    <span style="float: left">{{ item.deviceNo }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.deviceName }}</span>
                  </el-option>
                </el-select>
              </template>
            </vxe-table-column>
            <vxe-table-column
              field="deviceName"
              title="名称"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column field="content" title="内容" width="200">
              <template v-slot="{row,column}">
                <vxe-radio-group v-model="row[column.property]">
                  <vxe-radio :label="1" content="维修" />
                  <vxe-radio :label="2" content="保养" />
                </vxe-radio-group>
              </template>
            </vxe-table-column>
            <vxe-table-column
              field="warrantyContent"
              title="保修内容"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="feedback"
              title="保修情况反馈"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="remark"
              title="备注"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              v-if="deviceMaintenanceFormTitle === '新增设施设备维修保养记录'
                || deviceMaintenanceFormTitle === '修改设施设备维修保养记录'"
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-row>
          <el-col :span="8">
            <el-form-item label="复核结果" prop="reviewResult">
              <el-radio-group
                v-model="deviceMaintenanceForm.reviewResult"
                :disabled="deviceMaintenanceFormTitle !== '复核设施设备维修保养记录'
                  && deviceMaintenanceFormTitle !== '设施设备维修保养记录详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="deviceMaintenanceDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="deviceMaintenanceFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="维修保养日期">
        <el-date-picker v-model="searchForm.maintenanceDate" placeholder="请选择维修保养日期" value-format="yyyy-MM-dd" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['DEV_MAINTENANCE_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="deviceMaintenancePage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
    >
      <el-table-column label="维修保养日期">
        <template slot-scope="scope">
          <span v-if="scope.row.maintenanceDate">{{ scope.row.maintenanceDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="operator" label="操作人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.operateDate">{{ scope.row.operateDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewDate">{{ scope.row.reviewDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="复核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewResult === 1">通过</span>
          <span v-if="scope.row.reviewResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['DEV_MAINTENANCE_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['DEV_MAINTENANCE_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['DEV_MAINTENANCE_REVIEW']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            复核
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="deviceMaintenancePage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addDeviceMaintenance,
  deleteDeviceMaintenance,
  updateDeviceMaintenance,
  selectDeviceMaintenanceInfo,
  selectDeviceMaintenanceList,
  reviewDeviceMaintenance
} from '@/api/produce/deviceMaintenance'
import { selectBaseInfoList } from '@/api/device/baseInfo'

export default {
  data () {
    return {
      deviceMaintenanceDialogVisible: false,
      deviceMaintenanceFormTitle: '',
      deviceMaintenanceForm: {
        id: '',
        maintenanceDate: '',
        status: '',
        reviewResult: '',
        maintenanceDetail1Json: '',
        taskId: ''
      },
      deviceMaintenanceFormRules: {
        maintenanceDate: [{ required: true, message: '维修保养日期不能为空', trigger: ['blur', 'change']}]
      },
      deviceMaintenancePage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        maintenanceDate: ''
      },
      devList: [],
      detailList: []
    }
  },
  created () {
    selectDeviceMaintenanceList(this.searchForm).then(res => {
      this.deviceMaintenancePage = res
    })
    selectBaseInfoList().then(res => {
      this.devList = res.list
    })
  },
  methods: {
    deviceMaintenanceDialogClose () {
      this.$refs.deviceMaintenanceFormRef.resetFields()
      this.detailList = []
    },
    deviceMaintenanceFormSubmit () {
      if (this.deviceMaintenanceFormTitle === '设施设备维修保养记录详情') {
        this.deviceMaintenanceDialogVisible = false
        return
      }
      this.$refs.deviceMaintenanceFormRef.validate(async valid => {
        if (valid) {
          if (this.deviceMaintenanceFormTitle === '新增设施设备维修保养记录') {
            this.deviceMaintenanceForm.id = ''
            this.deviceMaintenanceForm.status = 1
            this.deviceMaintenanceForm.maintenanceDetail1Json = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await addDeviceMaintenance(this.deviceMaintenanceForm)
          } else if (this.deviceMaintenanceFormTitle === '修改设施设备维修保养记录') {
            this.deviceMaintenanceForm.maintenanceDetail1Json = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await updateDeviceMaintenance(this.deviceMaintenanceForm)
          } else if (this.deviceMaintenanceFormTitle === '复核设施设备维修保养记录') {
            this.deviceMaintenanceForm.status = 2
            await reviewDeviceMaintenance(this.deviceMaintenanceForm)
          }
          this.deviceMaintenancePage = await selectDeviceMaintenanceList(this.searchForm)
          this.deviceMaintenanceDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.deviceMaintenanceFormTitle = '新增设施设备维修保养记录'
      this.deviceMaintenanceDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteDeviceMaintenance(row.id)
        if (this.deviceMaintenancePage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.deviceMaintenancePage = await selectDeviceMaintenanceList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.deviceMaintenanceFormTitle = '修改设施设备维修保养记录'
      this.deviceMaintenanceDialogVisible = true
      this.selectDeviceMaintenanceInfoById(row.id)
    },
    handleReview (index, row) {
      this.deviceMaintenanceFormTitle = '复核设施设备维修保养记录'
      this.deviceMaintenanceDialogVisible = true
      this.selectDeviceMaintenanceInfoById(row.id)
    },
    handleInfo (index, row) {
      this.deviceMaintenanceFormTitle = '设施设备维修保养记录详情'
      this.deviceMaintenanceDialogVisible = true
      this.selectDeviceMaintenanceInfoById(row.id)
    },
    selectDeviceMaintenanceInfoById (id) {
      selectDeviceMaintenanceInfo(id).then(res => {
        this.deviceMaintenanceForm.id = res.id
        this.deviceMaintenanceForm.maintenanceDate = res.maintenanceDate
        this.deviceMaintenanceForm.status = res.status
        this.deviceMaintenanceForm.reviewResult = res.reviewResult
        this.deviceMaintenanceForm.taskId = res.taskId
        this.detailList = res.detailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectDeviceMaintenanceList(this.searchForm).then(res => {
        this.deviceMaintenancePage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectDeviceMaintenanceList(this.searchForm).then(res => {
        this.deviceMaintenancePage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectDeviceMaintenanceList(this.searchForm).then(res => {
        this.deviceMaintenancePage = res
      })
    },
    devChange (row) {
      if (row.deviceNo) {
        let dev = this.devList.find(item => item.deviceNo === row.deviceNo)
        row.deviceName = dev.deviceName
      } else {
        row.deviceName = ''
      }
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['DEV_MAINTENANCE_REVIEW']) && row.status === 1) {
        return 'review'
      }
      return ''
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    }
  }
}
</script>

<style>
</style>
