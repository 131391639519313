import axios from '@/common/axios'
import qs from 'qs'

export function addDeviceMaintenance (data) {
  return axios({
    method: 'post',
    url: '/production/deviceMaintenance/add',
    data: qs.stringify(data)
  })
}

export function deleteDeviceMaintenance (id) {
  return axios({
    method: 'delete',
    url: '/production/deviceMaintenance/delete/' + id
  })
}

export function updateDeviceMaintenance (data) {
  return axios({
    method: 'put',
    url: '/production/deviceMaintenance/update',
    data: qs.stringify(data)
  })
}

export function selectDeviceMaintenanceInfo (id) {
  return axios({
    method: 'get',
    url: '/production/deviceMaintenance/info/' + id
  })
}

export function selectDeviceMaintenanceList (query) {
  return axios({
    method: 'get',
    url: '/production/deviceMaintenance/list',
    params: query
  })
}

export function reviewDeviceMaintenance (data) {
  return axios({
    method: 'put',
    url: '/production/deviceMaintenance/updateReview',
    data: qs.stringify(data)
  })
}
